import { Fragment } from "react";
import ReactTooltip from 'react-tooltip';
import useLocalization from 'src/services/localization/useLocalization';
import styles, { getTooltipStyles } from "./SlotTooltip.styles";

export interface SlotTooltipProps {
  endOrderingTime: number;
  language: string;
}

const SlotTooltip = (props: SlotTooltipProps) => {
  const { language, endOrderingTime } = props;
  const localize = useLocalization();
  const { styles: tooltipStyles, className: tooltipClassName } = getTooltipStyles();

  const tooltipData = new Date(endOrderingTime)
    .toLocaleString(language, {
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  return (
    <span className="SlotTooltip">
      <span
        className="SlotTooltip__icon icon-info"
        data-tip={tooltipData}
        data-for='tooltip'
      />
      <ReactTooltip
        id='tooltip'
        effect='solid'
        className={tooltipClassName}
        offset={{ top: -5, left: 0 }}
        getContent={(dataTip: string) => (
          <Fragment>
            <div>{localize('general.order.info')}</div> {dataTip}
            {tooltipStyles}
          </Fragment>
        )}
      />
      <style jsx>{styles}</style>
    </span>
  );
};

export default SlotTooltip;
