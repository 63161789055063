import cn from 'classnames';
import * as React from 'react';
import { Fragment } from 'react';
import { styles } from './Container.styles';

interface Props {
  fullHeight?: boolean
  withoutPadding?: boolean
}

const Container: React.FC<Props> = (props) => {
  const { children, fullHeight, withoutPadding } = props;

  return (
    <Fragment>
      <div
        className={cn(
          'Container', {
          'Container_fullHeight': fullHeight,
          'Container_withoutPadding': withoutPadding,
        })}
      >
        {children}
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

Container.defaultProps = {
  fullHeight: false,
  withoutPadding: false,
};

export default Container;
