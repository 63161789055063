import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { styles } from './Carousel.styles';

interface Props {
  children: React.ReactElement[]
  scrollStep?: number
  dataMarker?: string
}

const Carousel = (props: Props) => {
  const {
    children,
    scrollStep = 400,
    dataMarker = 'Carousel',
  } = props;

  const carouselRef = useRef();

  const [position, setPosition] = useState(0);
  const [maxPosition, setMaxPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const handleLeftArrowClick = () => {
    const newPosition = position - scrollStep < 0
      ? 0
      : (position - scrollStep);

    setPosition(newPosition);
  };

  const handleRightArrowClick = () => {
    const newPosition = position + scrollStep > maxPosition
      ? maxPosition
      : (position + scrollStep);

    setPosition(newPosition);
  };

  useEffect(() => {
    const carousel: HTMLDivElement = carouselRef.current;

    if(typeof carousel?.scrollTo === 'function') {
      carousel?.scrollTo(position, 0);
    }

    if (position === 0) {
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(true);
    }

    if (position === maxPosition) {
      setShowRightArrow(false);
    } else {
      setShowRightArrow(true);
    }

  }, [position, maxPosition]);

  useEffect(() => {
    const carousel: HTMLDivElement = carouselRef.current;
    const maxPosition = carousel.scrollWidth - carousel.clientWidth;
    setMaxPosition(maxPosition);
  }, [children]);

  return (
    <div className='Carousel' data-marker={dataMarker}>
      <div
        className='Carousel__itemsWrapper'
        ref={carouselRef}
        data-testid='carousel'
      >
        {children}
      </div>

      { showLeftArrow &&
        <button
          className='Carousel_arrow Carousel_arrow__left icon-arrow-small-left'
          onClick={handleLeftArrowClick}
          data-testid='leftArrow'
          data-marker='Left Arrow'
        />
      }

      { showRightArrow &&
        <button
          className='Carousel_arrow Carousel_arrow__right icon-arrow-small-right'
          onClick={handleRightArrowClick}
          data-testid='rightArrow'
          data-marker='Right Arrow'
        />
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default Carousel;
