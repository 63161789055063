import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';

export const styles = css`
    .Carousel {
      position: relative;
    }

    .Carousel__itemsWrapper {
      display: flex;
      width: 100%;
      scroll-behavior: smooth;
      overflow-x: hidden;
    }

    .Carousel_arrow {
      position: absolute;
      padding: 0 12px;
      width: 78px;
      height: 100%;
      background: transparent;
      border: none;
      outline: none;
      font-size: 10px;
      line-height: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--uiColors-Button-accentedText)
    }

    .Carousel_arrow:hover {
      font-weight: 600;
      color: var(--uiColors-Button-accentedHoverText)
    }

    .Carousel_arrow__left {
      left: 0;
      top: 0;
      background-image: linear-gradient(to right, rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
      justify-content: flex-start;
    }

    .Carousel_arrow__right {
      right: 0;
      top: 0;
      background-image: linear-gradient(to left, rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);;
      justify-content: flex-end;
    }

    @media ${phone} {
      .Carousel__itemsWrapper {
        overflow-x: scroll;
      }

      .Carousel_arrow {
        display: none;
      }
    }
  `;
