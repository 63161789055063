import css from 'styled-jsx/css';
import typography from '../../styles/typography';

export default css`
  .HeaderPromotionLine {
    background-color: var(--colors-lightGreen);
  }

  .HeaderPromotionLine__container {
    position: relative;
    max-width: 1366px;
    margin: 0 auto;
  }

  .HeaderPromotionLine__content {
    padding: 8px 40px;
    margin: 0 auto;
    ${ typography.smallAccentedText }
    line-height: 16px;
    text-align: center;
  }

  .HeaderPromotionLine__link {
    color: var(--colors-zakazGreen);
  }

  .HeaderPromotionLine__buttonClose {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;
