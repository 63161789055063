export const sendExperimentIsInitialized = (id: string, variant: number) => {
  const abEventName = 'ab_test_start';
  //initializing event must be sent only for first showing of experiment functional
  const eventWillSendedEarly = window.dataLayer.some(e => e?.event === abEventName && e?.test_name === id);

  if(!eventWillSendedEarly) {
    window.dataLayer.push({
      event: abEventName,
      test_name: id,
      test_variant: variant,
    });
  }
};
