import { useContext } from 'react';
import HeaderPromotionContext from './HeaderPromotionContext';

const useHeaderPromotionContext = () => {
  const value = useContext(HeaderPromotionContext);

  if (value === null) {
    throw new Error('HeaderPromotionContext is used out of provider');
  }

  return value;
};

export default useHeaderPromotionContext;
