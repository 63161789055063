import cn from 'classnames';
import useChainService from 'src/services/chain/useChainService';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { useTitle } from '../MainPageHead/MainPageHeadHooks';
import { styles } from './Logo.styles';

const Logo = () => {
  const { skin, fetchConfig: { language } } = useAppConfig();
  const title = useTitle();
  const chain = useChainService();
  const logoLink = chain.logoLinks.logo;

  return (
    <a href={`/${language}/`}>
      <div data-marker='Logo' className={cn(
        'Logo', {
        'Logo_high': skin === 'metro' || skin === 'megamarket' || skin === 'winetime' || skin === 'kharkiv',
      })}>
        <img
          className='Logo__image'
          src={logoLink}
          title={title}
          alt={title}
        />
        <style jsx>{styles}</style>
      </div>
    </a>
  );
};

export default Logo;
