export const sendBannerDisplayed = (pageSlug: string) => {
  window.dataLayer.push({
    'event': 'banner_above_header_viewed',
    'baner': pageSlug,
  });
};

export const sendBannerButtonClick = (pageSlug: string) => {
  window.dataLayer.push({
    'event': 'banner_above_header_button_click',
    'baner': pageSlug,
  });
};
export const sendBannerClosed = (pageSlug: string) => {
  window.dataLayer.push({
    'event': 'banner_above_header_closed',
    'baner': pageSlug,
  });
};
