import cn from 'classnames';
import * as React from 'react';
import Checkbox from '../../views/Checkbox/Checkbox';
import { styles } from './CheckboxWithLabel.styles';

export interface CheckboxWithLabelProps {
  checked?: boolean;
  disabled?: boolean;
  labelId: string;
  dataMarkerValue?: string;
  dataTestId?: string;
  children: React.ReactNode;
  onClick(): void;
  fullWidth?: boolean;
  bold?: boolean;
}

export default function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  const { dataMarkerValue, checked, labelId, children, disabled, onClick, dataTestId, fullWidth, bold } = props;

  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      className={cn(
        'CheckboxWithLabel', {
        'CheckboxWithLabel_disabled': disabled,
        'CheckboxWithLabel__fullWidth': fullWidth,
      })}
    >
      <Checkbox
        dataMarkerValue={dataMarkerValue}
        labelledby={labelId}
        disabled={disabled}
        hovered={hovered}
        checked={checked}
        onClick={onClick}
        dataTestId={dataTestId}
      />

      <label
        onClick={onClick}
        id={labelId}
        aria-disabled={disabled}
        aria-label={labelId}
        className={cn(
          'CheckboxWithLabel__label', {
          'CheckboxWithLabel__label_disabled': disabled,
          'CheckboxWithLabel__label_fullWidth': fullWidth,
          'CheckboxWithLabel__label_bold': bold,
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {children}
      </label>

      <style jsx>{styles}</style>
    </div>
  );
}
