import { desktop } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
    .Checkbox {
      border: 1px solid var(--colors-brandLightGrey2);
      border-radius: 3px;
      width: 17px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      transition: 0.2s border-color ease-in-out,
        0.2s background-color ease-in-out;
    }

    .Checkbox_checked {
      background-color: var(--uiColors-Checkbox-checked);
      border-color: var(--uiColors-Checkbox-checked);
    }

    .Checkbox_disabled {
      opacity: 0.4;
    }

    .Checkbox__icon {
      font-size: 10px;
      line-height: 1;
      color: #fff;
      display: none;
    }

    .Checkbox_checked .Checkbox__icon {
      display: block;
    }

    @media ${desktop} {
      .Checkbox:not(.Checkbox_disabled) {
        cursor: pointer;
      }

      .Checkbox_hovered:not(.Checkbox_disabled, .Checkbox_checked),
      .Checkbox:hover:not(.Checkbox_disabled, .Checkbox_checked) {
        border-color: var(--colors-brandLightGrey8);
      }

      .Checkbox_disabled {
        opacity: 0.4;
      }
    }
  `;
