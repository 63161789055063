import { useEffect, useState } from "react";
import { IS_SERVER } from 'src/redux/constants';
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { sendExperimentIsInitialized } from "./helpers/analytics";
import { getExperimentVariant } from "./helpers/getExperimentVariant";
import { DEFAULT_EXPERIMENT_INDEX } from "./helpers/getRandomFromPercentages";

export const useExperiment = (id: string) => {
  const { chain: currentChain, experiments } = useAppConfig();
  const [variant, setVariant] = useState(DEFAULT_EXPERIMENT_INDEX);

  useEffect(() => {
    const experiment = experiments[id];

    if (experiment) {
      const variant = getExperimentVariant(experiment, currentChain, id);
      sendExperimentIsInitialized(id, variant);
      setVariant(variant);
    }
  }, [currentChain, experiments, id]);

  return variant;
};

/**
 * @deprecated
 * @todo remove after experiment.
 */
export const useExperimentClientSideOnly = (id: string) => {
  const { chain: currentChain, experiments } = useAppConfig();
  const [variant] = useState(() => {
    const experiment = experiments[id];

    if (IS_SERVER || !experiment) {
      return DEFAULT_EXPERIMENT_INDEX;
    }

    const variant = getExperimentVariant(experiment, currentChain, id);
    sendExperimentIsInitialized(id, variant);

    return variant;
  });

  return variant;
};
