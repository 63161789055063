import { ExperimentConfig } from "../types";
import { getVariantFromCookies, setVariantToCookies } from "./cookies";
import { DEFAULT_EXPERIMENT_INDEX, getRandomFromPercentages } from "./getRandomFromPercentages";

export const getExperimentVariant = (experiment: ExperimentConfig, currentChain: string, id: string): number => {
  const {
    chains,
    endDate,
    distribution,
  } = experiment;

  const currentDate = new Date();
  const expireDate = new Date(endDate);

  const isExpired = currentDate > expireDate;
  const forAllChains = chains.some(chain => chain === '*');
  const forCurrentChain = chains.some(chain => chain === currentChain);
  const isUnavailableForCurrentChain = !forCurrentChain && !forAllChains;

  if(isExpired || isUnavailableForCurrentChain) {
    return DEFAULT_EXPERIMENT_INDEX;
  }
  const variantFromCookies = getVariantFromCookies(id);

  if(variantFromCookies) {
    return Number(variantFromCookies);
  }

  const randomVariant = getRandomFromPercentages(distribution);
  /*
   sets the cookie only for the random variant,
   because there is a case when the experiment is disabled for some chain,
   and in other chains the variant must be synchronized
   */
  setVariantToCookies(id, randomVariant);

  return randomVariant;
};
