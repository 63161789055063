import css from 'styled-jsx/css';

export const styles = css`
  .Logo {
    text-decoration: none;
    text-align: start;
    height: 28px;
    width: auto;
    display: flex;
  }
  .Logo__image {
    height: 100%;
    max-width: 100%;
  }
  `;
