import cn from 'classnames';
import getDataStatus from 'src/utils/system/getDataStatus';
import { styles } from './Checkbox.styles';

interface Props {
  checked?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  labelledby: string;
  dataTestId?: string;
  onClick(): void;
  dataMarkerValue?: string
}

export default function Checkbox(props: Props) {
  const { checked, hovered, labelledby, dataTestId, disabled, onClick, dataMarkerValue = 'Checkbox' } = props;

  return (
    <div
      onClick={onClick}
      aria-checked={checked}
      aria-disabled={disabled}
      aria-labelledby={labelledby}
      role='checkbox'
      data-marker={dataMarkerValue}
      data-status={getDataStatus(checked)}
      data-testid={dataTestId}
      className={cn(
        'Checkbox', {
        'Checkbox_checked': checked,
        'Checkbox_hovered': hovered,
        'Checkbox_disabled': disabled,
      })}
    >
      <span
        role='presentation'
        className='Checkbox__icon icon-check'
      />

      <style jsx>{styles}</style>
    </div>
  );
}
