import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .Input {
      ${typography.h5}
      outline: none;
      height: 40px;
      width: 100%;
      margin: 0;
      padding: 0 20px;
      border: 1px solid;
      border-radius: var(--border-radius-roundBox);
      border-color: var(--uiColors-globals-formControlBorder);
      transition: 0.2s border-color ease-in-out;
    }
    .Input_smallHeight {
      height: 36px;
    }
    .Input_smallPadding {
      padding: 0 15px;
    }
    .Input:hover,
    .Input:focus {
      border-color: var(--uiColors-globals-formControlHoverFocusBorder);
    }
    .Input_hasError:hover,
    .Input_hasError:focus,
    .Input_hasError {
      border-color: var(--uiColors-globals-formControlHasErrorBorder);
    }
    .Input_readOnly {
      color: var(--uiColors-globals-formControlReadOnlyText);
    }
    .Input_readOnly:hover,
    .Input_readOnly:focus,
    .Input_readOnly {
      background-color: var(--uiColors-globals-formControlReadOnlyBackground);
      border-color: var(--uiColors-globals-formControlBorder);
    }
    .Input_textAlignCenter {
      text-align: center;
    }
    .Input:disabled {
      background: #F8F8F8;
      color: #9F9F9F;
    }
    .Input:disabled:hover {
      border-color: var(--uiColors-globals-formControlBorder);
    }
  `;
