import css from "styled-jsx/css";

export const getTooltipStyles = () => css.resolve`
  div {
    padding: 7px 15px;
    border-radius: 15.5px;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    background: var(--uiColors-site);
  }
  div:after {
    border-top-color: var(--uiColors-site);
  }
`;

const styles = css`
  .SlotTooltip {
    color: var(--colors-doveGray);
  }
  .SlotTooltip__icon {
    font-size: 14px;
    padding: 0 4px;
  }
`;

export default styles;
