import cn from 'classnames';
import { ChangeEvent, ComponentProps, Fragment } from 'react';
import { styles } from './Input.styles';

type InputMode = "search" | "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal";

export type BaseInputProps = Omit<ComponentProps<'input'>,  'className' | 'ref' | 'onChange' | 'pattern'>;

export interface InputProps extends BaseInputProps {
  hasError?: boolean;
  onChange?: (value: string) => void;
  smallHeight?: boolean;
  textAlignCenter?: boolean;
  value?: string;
  dataMarkerValue?: string;
  dataTestId?: string;
  pattern?: RegExp;
  ariaLabel?: string;
  inputMode?: InputMode;
  smallPadding?: boolean;
}

const Input = (props: InputProps) => {
  const {
    hasError,
    onChange = () => {},
    textAlignCenter,
    value,
    dataMarkerValue,
    dataTestId,
    pattern,
    ariaLabel,
    readOnly,
    smallHeight,
    inputMode,
    smallPadding,
    required,
    name,
    type = 'text',
    ...rest
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;

    if (pattern && !pattern.test(value)) return;

    onChange(value);
  };

  return (
    <Fragment>
      <input
        className={cn('Input', {
          Input_hasError: hasError,
          Input_readOnly: readOnly,
          Input_textAlignCenter: textAlignCenter,
          Input_smallHeight: smallHeight,
          Input_smallPadding: smallPadding,
        })}
        id={name}
        data-testid={dataTestId}
        data-marker={dataMarkerValue}
        name={name}
        onChange={handleChange}
        value={value}
        aria-invalid={hasError}
        aria-readonly={readOnly}
        aria-required={required}
        aria-label={ariaLabel}
        inputMode={inputMode}
        type={type}
        {...rest}
      />

      <style jsx>{styles}</style>
    </Fragment>
  );
};

Input.defaultProps = {
  hasError: false,
  name: '',
  textAlignCenter: false,
  value: '',
  dataTestId: 'Input',
  pattern: null,
};

export default Input;
