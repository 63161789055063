import Cookies from "js-cookie";

export const getVariantFromCookies = (id: string): string | null => {
  const experimentVariant = Cookies.get(getExperimentCookieKey(id));

  return experimentVariant || null;
};

const getDomainForCookies = () => {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost')) {
    return null;
  }

  const array = hostname.split('.');
  /**
   * remove first segment of hostname for crossdomain cookie
   * test => test.zakaz.ua
   * prod => zakaz.ua
   */
  array.shift();
  return array.join('.');
};

export const setVariantToCookies = (id: string, variant: number) => {
  const domain = getDomainForCookies();
  Cookies.set(getExperimentCookieKey(id), String(variant), { expires: 365, ...(domain && { domain }) });
};

export const getExperimentCookieKey = (id: string) => 'experiment_' + id;
