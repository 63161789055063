const shortDayOfWeekTranslationIdMap = {
  0: 'date_Sunday',
  1: 'date_Monday',
  2: 'date_Tuesday',
  3: 'date_Wednesday',
  4: 'date_Thursday',
  5: 'date_Friday',
  6: 'date_Saturday',
};

const getDayOfWeekShortTranslationId = (date: Date) => {
  return shortDayOfWeekTranslationIdMap[date.getDay()];
};

export default getDayOfWeekShortTranslationId;
