import css from 'styled-jsx/css';
import { phone, tablet } from '../../../styles/media';

export const styles = css`
  .Container {
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    max-width: 1366px;
  }
  .Container_fullHeight {
    height: 100%;
  }
  @media ${tablet} {
    .Container {
      width: 100%;
      padding: 0 20px;
    }
  }
  @media ${phone} {
    .Container {
      padding: 0 10px;
    }
    .Container_withoutPadding {
      padding: 0;
    }
  }
  `;
