
export const DEFAULT_EXPERIMENT_INDEX = 0;

export function getRandomFromPercentages(probabilities: number[]) {
  const totalPercentage = probabilities.reduce((acc, val) => acc + val, 0);
  const maxPercentage = 100;

  if(totalPercentage !== maxPercentage) {
    return DEFAULT_EXPERIMENT_INDEX;
  }

  const randomPercent = Math.random() * maxPercentage;

  let currentPercent = 0;


  /**
   * Щоб охопити діапазон значень від 0 до 100 -
   *  порівнюємо випадкове число з сумою відсотків
   *  (яка збільшується при кожній ітерації відповідно до заданих імовірностей).
   */

  for (let i = 0; i < probabilities.length; i++) {
    currentPercent += probabilities[i];
    if (randomPercent < currentPercent) {
      return i;
    }
  }

  return DEFAULT_EXPERIMENT_INDEX;
}
