import { useEffect } from 'react';
import styles from './HeaderPromotionLine.styles';
import { sendBannerButtonClick, sendBannerClosed, sendBannerDisplayed } from './analytics';
import { useRouter } from 'next/router';

export interface HeaderPromotionLineProps {
  content: string;
  link: string;
  linkLabel: string;
  onClose(): void;
}

const HeaderPromotionLine = (props: HeaderPromotionLineProps) => {
  const { content, link, linkLabel, onClose } = props;
  const { pathname } = useRouter();
  const pageSlugWithoutLang = pathname.split('/').filter(i => i !== '[lang]').join('/');

  useEffect(() => {
    sendBannerDisplayed(pageSlugWithoutLang);
  }, [pageSlugWithoutLang]);

  const handleClose = () => {
    onClose();
    sendBannerClosed(pageSlugWithoutLang);
  };

  const handleLinkClick = () => {
    sendBannerButtonClick(pageSlugWithoutLang);
  };

  return (
    <div className="HeaderPromotionLine" data-marker={'Header Promotion Line'}>
      <div className="HeaderPromotionLine__container">
        <div className="HeaderPromotionLine__content">
          {content}
          {' '}
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            onClick={handleLinkClick}
            className="HeaderPromotionLine__link"
          >
            {linkLabel}
          </a>
        </div>

        <button
          className="HeaderPromotionLine__buttonClose"
          onClick={handleClose}
          aria-label="button close"
          data-marker="Close Header Promotion"
        >
          <i className="icon-close" />
        </button>
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default HeaderPromotionLine;
