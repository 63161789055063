import { useEffect, useRef } from 'react';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import useStoreService from 'src/services/storeManagement/useStoreService';
import useScheduleDeliveryProps from 'src/swr/useScheduleDelivery/useScheduleDeliveryProps';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { useAppSelector } from '../../../redux/hooks';
import useScheduleDelivery from '../../../swr/useScheduleDelivery/useScheduleDelivery';
import { DeliveryContractSetData, sendDeliveryContractSet } from '../../../utils/marketing/enhancedEcommerce';

interface ScheduleDeliveryAnalyticsWatcherProps {
  event: DeliveryContractSetData['event'],
}

const ScheduleDeliveryAnalyticsWatcher = (props: ScheduleDeliveryAnalyticsWatcherProps) => {
  const { event } = props;

  const scheduleDeliveryProps = useScheduleDeliveryProps();
  const delivery = useAppSelector(selectSelectedDelivery);

  const { scheduleDeliveryList } = useScheduleDelivery(scheduleDeliveryProps);
  const deliveryZone = scheduleDeliveryList[0]?.delivery_zone;
  const deliveryContractSetDataRef = useRef<DeliveryContractSetData>();
  const { chain } = useAppConfig();
  const store = useStoreService();

  deliveryContractSetDataRef.current = {
    event,
    storeId: store.id,
    chain,
    delivery,
    scheduleDeliveryList,
  };

  useEffect(() => {
    if(deliveryZone && delivery) {
      sendDeliveryContractSet(deliveryContractSetDataRef.current);
    }
  }, [deliveryZone, delivery]);

  return null;
};

export default ScheduleDeliveryAnalyticsWatcher;
